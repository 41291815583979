const convertToUrlPath = (title) => {
  return title
    .toLowerCase()
    .split("-")
    .join("")
    .split("  ")
    .join(" ")
    .split(" ")
    .join("-")
    .split(":")
    .join("")
    .split("?")
    .join("")
    .split("!")
    .join("")
    .split("’")
    .join("");
};

const getShortValueFromDictionary = (
  dictionaryEdges,
  dictionaryKey,
  language
) => {
  const locale = language ? (language === "nl" ? "nl-BE" : "en-EU") : "en-EU";
  const edge = dictionaryEdges.find(
    (x) => x.node.key === dictionaryKey && x.node["node_locale"] === locale
  );
  const value = edge === undefined ? undefined : edge.node.shortValue;

  return value ? value : dictionaryKey;
};

const getTrailedUrl = (href) => {
  if (href.length > 2) {
    href = href[0] !== "/" ? "/" + href : href;
    href = href[href.length - 1] !== "/" ? href + "/" : href;
  }

  return href;
};

const useIntersect = (selector, toggleClass) => {
  let boxElement;
  let prevRatio = 0.0;

  // Set things up
  boxElement = document.querySelector(selector);
  boxElement && createObserver();

  function createObserver() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: buildThresholdList(),
    };
    let observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(boxElement);
  }

  function buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);

    return thresholds;
  }

  function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > prevRatio) {
        document.body.classList.remove(toggleClass);
      } else {
        document.body.classList.add(toggleClass);
      }

      prevRatio = entry.intersectionRatio;
    });
  }
};

module.exports.useIntersect = useIntersect;
module.exports.convertToUrlPath = convertToUrlPath;
module.exports.getShortValueFromDictionary = getShortValueFromDictionary;
module.exports.getTrailedUrl = getTrailedUrl;

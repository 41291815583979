import React from "react";
import Img from "gatsby-image";
import "../../scss/molecules/_image.scss";

class Image extends React.Component {
  render() {
    var aspectRatioBox = this.props.aspectRatio ? (
      <div className="image-clear"></div>
    ) : (
      ""
    );
    var imageTag;

    if (this.props.extension && this.props.extension === "svg") {
      imageTag = (
        <img
          className="image-img"
          src={this.props.src}
          alt={this.props.alt}
          draggable={false}
        />
      );
    } else if (this.props.fluid) {
      this.props.fluid.aspectRatio = this.props.fluid.aspectRatio
        ? this.props.fluid.aspectRatio
        : 1;
      imageTag = (
        <Img
          className="image-img"
          fluid={this.props.fluid}
          alt={this.props.alt}
          draggable={false}
          loading={this.props.loading}
          fadeIn={this.props.loading === "eager" ? false : true}
        />
      );
    } else if (this.props.fixed) {
      imageTag = (
        <Img
          className="image-img"
          fixed={this.props.fixed}
          alt={this.props.alt}
          draggable={false}
          loading={this.props.loading}
          fadeIn={this.props.loading === "eager" ? false : true}
        />
      );
    } else if (this.props.src) {
      imageTag = (
        <img className="image-img" src={this.props.src} alt={this.props.alt} />
      );
    }

    return (
      <div
        className={
          "image" +
          (this.props.overlay === "gradient" ? " image-with-gradient" : "") +
          (this.props.negateTop ? " image-with-negate-top" : "") +
          (this.props.negateBottom ? " image-with-negate-bottom" : "") +
          (this.props.negateLeft ? " image-with-negate-left" : "") +
          (this.props.negateRight ? " image-with-negate-right" : "") +
          (this.props.className ? " " + this.props.className : "") +
          (this.props.aspectRatio
            ? " image-with-aspect-" + this.props.aspectRatio
            : "")
        }
        style={
          this.props.maxWidth
            ? {
                maxWidth: this.props.maxWidth,
                margin: "0 auto",
              }
            : {}
        }
      >
        {this.props.children}
        {imageTag}
        {aspectRatioBox}
      </div>
    );
  }
}

export default Image;
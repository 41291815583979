import React, {Component} from "react";
import { Link } from "gatsby";
import "../../scss/molecules/_buttons.scss";

const helpers = require("../../helpers");

class Button extends Component {
  render() {
    let href = helpers.getTrailedUrl(this.props.to ? this.props.to : "");

    return (
      <Link
        className={
          "btn" + (this.props.className ? " " + this.props.className : "")
        }
        to={href}
        onClick={this.props.onClick}
      >
        <span>{this.props.children}</span>
      </Link>
    );
  }
}

export default Button;

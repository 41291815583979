import React, { Component } from "react";
import Doormat from "../../components/molecules/doormat";
import Contact from "../../components/atoms/contact";
import Logo from "../../components/atoms/logo";

import "../../scss/organisms/_footer.scss";

class footer extends Component {
  constructor(props) {
    super(props);

    this.doormatPages = this.props.pagesData;
    this.contactEmail = this.props.generalData.footerEmail;
    this.contactPhoneNumber = this.props.generalData.footerPhoneNumber;
  }

  render() {
    return (
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xl-7 d-flex align-items-center ml-xl-auto">
              <Doormat pages={this.doormatPages} />
            </div>
            <div className="col-10 col-xl-2 d-flex align-items-center mx-xl-0">
              <Contact email={this.contactEmail} phoneNumber={this.contactPhoneNumber} />
            </div>
            <div className="col-2 col-xl-2 d-flex align-items-center justify-content-end mr-xl-auto">
              <Logo />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default footer;

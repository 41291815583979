import React, { Component } from "react";
import { Link } from "gatsby";

import "../../scss/molecules/_doormat.scss";

class doormat extends Component {
  constructor(props) {
    super(props);

    this.listItems = this.props.pages
      .sort(
        (a, b) =>
          (a.node.navigationOrder || 100) - (b.node.navigationOrder || 100)
      )
      .map(
        (page) =>
          page.node.slug &&
          page.node.pageName &&
          !page.node.hideInFooter && (
            <li className="nav-item" key={page.node.id}>
              <Link
                to={`${page.node.slug}`}
                className="nav-link"
                title={page.node.pageName}
              >
                {page.node.pageName}
              </Link>
            </li>
          )
      );
  }

  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark px-md-0">
        <ul className="navbar-nav">{this.listItems}</ul>
      </nav>
    );
  }
}

export default doormat;

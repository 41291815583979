import React, { Component } from "react";
import Topnav from "../../components/molecules/topnav";

class header extends Component {
  constructor(props) {
    super(props);

    this.brandLogo = this.props.generalData.logo.fluid;
    this.pages = this.props.pagesData;
  }

  render() {
    return (
      <header>
        <Topnav brandLogo={this.brandLogo} pages={this.pages} />
      </header>
    );
  }
}

export default header;

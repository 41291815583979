import React, { Component } from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

class rte extends Component {
  render() {
    const H2 = ({ children }) => <h2>{children}</h2>;
    const H3 = ({ children }) => <h3>{children}</h3>;
    const Bold = ({ children }) => <strong>{children}</strong>;
    const Text = ({ children }) => <p>{children}</p>;
    const richTextOptions = {
      renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <Text>{children}</Text>;
        },
        [BLOCKS.HEADING_2]: (node, children) => {
            return <H2>{node.content[0].value.replace(/\s/g, ' ')}</H2>;
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return <H3>{node.content[0].value.replace(/\s/g, ' ')}</H3>;
        }
      }
    };

    const value = this.props.content;

    return <>{value && renderRichText(value, richTextOptions)}</>;
  }
}

export default rte;

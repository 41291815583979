import React from "react";
import "../scss/devlab.scss";
import "../scss/organisms/_layout.scss";

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return <>{children}</>;
  }
}

export default Layout;

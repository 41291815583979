import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

class contact extends Component {
  constructor(props) {
    super(props);

    this.email = this.props.email;
    this.mailToAddress = ['mailto', this.email].join(':');
    this.phoneNumber = this.props.phoneNumber;
    this.telReference = ['tel', this.phoneNumber.replace(/\s/g, '')].join(':');
  }

  render() {
    return (
      <nav className="navbar navbar-dark">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a href={this.mailToAddress} className="nav-link" title={this.email}>
              <FontAwesomeIcon icon={faEnvelope} />
              <span>{this.email}</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              href={this.telReference}
              className="nav-link"
              title={this.phoneNumber}
            >
              <FontAwesomeIcon icon={faPhone} />
              <span>{this.phoneNumber}</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default contact;

import React, { Component } from "react";
import Button from "../molecules/button";
import "../../scss/molecules/_button-group.scss";

class buttonGroup extends Component {
  constructor(props) {
    super(props);

    this.className = [
      "btn-group",
      this.props.center ? "justify-content-center px-0" : "",
    ].join(" ");
    this.buttons =
      this.props.buttons &&
      this.props.buttons.map((cta) => (
        <Button
          key={cta.id}
          to={
            cta.target["__typename"] === "ContentfulPage"
              ? cta.target.slug
              : `careers/${cta.target.slug}`
          }
          className={[cta.textVariant, cta.buttonVariant].join(" ")}
        >
          {cta.buttonText}
        </Button>
      ));
  }
  render() {
    return <div className={this.className}>{this.buttons}</div>;
  }
}

export default buttonGroup;

import React, { Component } from 'react';

class logo extends Component {
    render() {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="87.54" height="138.6" viewBox="0 0 87.54 138.6">
                    <polygon fill="#fff" points="57.2 56.29 0 25.04 0 0 57.2 31.25 57.2 56.29"/>
                    <polygon fill="#fff" points="57.2 56.29 0 25.04 0 0 57.2 31.25 57.2 56.29"/>
                    <polygon fill="#fff" points="57.2 31.25 0 62.5 0 87.54 57.2 56.29 57.2 31.25"/>
                    <polygon fill="#fff" points="30.34 107.34 87.54 76.09 87.54 51.06 30.34 82.31 30.34 107.34"/>
                    <polygon fill="#fff" points="30.34 107.34 87.54 76.09 87.54 51.06 30.34 82.31 30.34 107.34"/>
                    <polygon fill="#fff" points="30.34 82.31 87.54 113.56 87.54 138.6 30.34 107.34 30.34 82.31"/>
                </svg>
            </>
        );
    }
}

export default logo;
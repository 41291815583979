import React, { Component } from "react";
import { Link } from "gatsby";

import Image from "./image";
import "../../scss/molecules/_topnav.scss";

const helpers = require("../../helpers");

class Nav extends Component {
  state = { collapse: true };

  componentDidMount() {
    helpers.useIntersect(".navbar-collapse", "navbar-collapsed");
  }

  constructor(props) {
    super(props);

    this.brandLogo = this.props.brandLogo;
    this.listItems = this.props.pages
      .sort(
        (a, b) =>
          (a.node.navigationOrder || 100) - (b.node.navigationOrder || 100)
      )
      .map(
        (page) =>
          page.node.slug &&
          page.node.pageName &&
          !page.node.hideInNavigation && (
            <li className="nav-item" key={page.node.id}>
              <Link
                to={`${page.node.slug}`}
                className="nav-link"
                title={page.node.pageName}
              >
                {page.node.pageName}
              </Link>
            </li>
          )
      );
  }

  render() {
    return (
      <>
        <nav
          className={[
            "navbar",
            "navbar-expand-lg",
            "navbar-light",
            "fixed-top",
            this.state.collapse ? "" : "active",
          ].join(" ")}
        >
          <a className="navbar-brand" href="/">
            <Image fluid={this.brandLogo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#topnav"
            aria-controls="topnav"
            aria-expanded={!this.state.collapse}
            aria-label="Toggle navigation"
            onClick={() => {
              this.setState({ collapse: !this.state.collapse });
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={[
              "collapse",
              "navbar-collapse",
              "fade",
              !this.state.collapse ? "show" : "",
            ].join(" ")}
            id="topnav"
          >
            <ul className="navbar-nav ml-lg-auto">
              {this.listItems}
              {/* <li className="nav-item">
                <a className="btn btn-primary" href="#">
                  Contact
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Nav;
